<template>
	<div>
		<v-main>
			<v-row justify="center" class="text-center fill-height">
				<v-col cols="12" align-self="end">
					<v-progress-circular
						:size="70"
						:width="7"
						color="primary"
						indeterminate
					></v-progress-circular>
				</v-col>
				<v-col class="font-weight-bold">Loading ...</v-col>
			</v-row>
		</v-main>
	</div>
</template>

<script>
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { mapGetters, mapActions } from "vuex"

export default {
	mixins: [ 
		errorHandlerMixin
	],
	data() {
		return {
			type: null,
		}
	},
	computed: {
		...mapGetters(["redirectAfterLogin"]),
	},
	created(){
		const parsedParams = {};
		this.$route.hash.split('&')
			.map(part => part.replace(/^#/, ''))
			.forEach(param => {
				const parts = param.split('=');
				parsedParams[parts[0]] = parts[1];
			});
		if(parsedParams.access_token && this.$route.params.type){
			this.oauth2_verify(this.$route.params.type, parsedParams.access_token)
		}
		// await this.axios.get({ url: '/sanctum/csrf-cookie', baseURL: '/' }) // reuqired for cross-domain
	},
	methods:{
		oauth2_verify(type, token){
			this.$auth.oauth2(type, {
				url: 'auth/'+type+'/login',
				code: true,
				data: {
					access_token: token
				},
				state: this.$route.query.state,
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		redirectUser(role_id = null){
			let home = {
				"3" : "/new-order",
			}

			if(this.redirectAfterLogin)
				return this.$router.push("/new-order/customization")
			else
			{
				if(home[role_id])
					return this.$router.push(home[role_id])
				
				return this.$router.push("/order-histories")
			}
		},
	}
}
</script>